<template>
  <div>
    <FilterTessereGoldEconomato
      @getEconomatoList="getEconomatoListResetPage"
      @resetFilters="resetFilters"
    />
    <TableTessereGoldEconomato
      @getEconomatoList="getEconomatoList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import { defineComponent, onMounted, computed, getCurrentInstance } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import FilterTessereGoldEconomato from "@/components/components-fit/gestione/economato/FiltriTessereGoldEconomato.vue";
import TableTessereGoldEconomato from "@/components/components-fit/gestione/economato/TableTessereGoldEconomato.vue";

export default defineComponent({
  name: "tessere-gold-economato",
  components: {
    FilterTessereGoldEconomato,
    TableTessereGoldEconomato,
  },
  props: {},
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Economato");
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    const anno = computed(() => store.getters.stagioneSelected);

    const idComitatoRegionale = computed(
      () => store.getters.idComitatoRegionaleTessereGold
    );
    const idPersona = computed(() => store.getters.idTesseratoTessereGold);
    const idSocieta = computed(() => store.getters.idSocietaTessereGold);
    const startDateEmissione = computed(
      () => store.getters.dataEmissioneDaTessereGold
    );
    const endDateEmissione = computed(
      () => store.getters.dataEmissioneATessereGold
    );
    const startDateApprovazione = computed(
      () => store.getters.dataApprovazioneDaTessereGold
    );
    const endDateApprovazione = computed(
      () => store.getters.dataApprovazioneATessereGold
    );
    const tipoPagamento = computed(
      () => store.getters.tipoPagamentoTessereGold
    );
    const approvato = computed(() => store.getters.approvatoTessereGold);
    const mese = computed(() => store.getters.meseTessereGold);
    const rowsToSkip = computed(() => store.getters.rowsToSkipTessereGold);
    const fetchRows = computed(() => store.getters.fetchRowsTessereGold);
    const sortColumn = computed(() => store.getters.sortColumnTessereGold);
    const sortOrder = computed(() => store.getters.sortOrderTessereGold);

    const getEconomatoList = () => {
      const localKeys = {
        stagione: anno.value,
        id_comitato: idComitatoRegionale.value,
        data_ins_da: startDateEmissione.value,
        data_ins_a: endDateEmissione.value,
        data_appr_da: startDateApprovazione.value,
        data_appr_a: endDateApprovazione.value,
        id_persona: idPersona.value,
        id_societa: idSocieta.value,
        pagato_tramite: tipoPagamento.value,
        approvato: approvato.value,
        mese: mese.value,
        rowstoskip: rowsToSkip.value,
        fetchrows: fetchRows.value,
        sortcolumn: sortColumn.value,
        sortorder: sortOrder.value,
      };
      store.dispatch("setStoreListData", {
        keys: localKeys,
        apiLink: globalApi.ECONOMATO_TESSERE_GOLD_LIST,
        itemName: "economato_tessere_gold_list",
      });
    };

    const resetFilters = () => {
      store.commit("resetFiltersTessereGold");
      getEconomatoList();
    };

    const loaded = computed(() =>
      store.getters.getStateFromName("loadedeconomato_tessere_gold_list")
    );
    const getEconomatoListResetPage = () => {
      if (!loaded.value) return;
      store.commit("resetRowsToSkipTessereGold");
      getEconomatoList();
    };
    getEconomatoList();

    return {
      getEconomatoList,
      resetFilters,
      getEconomatoListResetPage,
    };
  },
});
</script>
