<template>
  <div
    style="position: relative; width: 100%"
    v-click-away="onClickAway"
    v-show="list.length && focusOnInput"
  >
    <ul class="list">
      <li
        class="text-hover-primary"
        style="cursor: pointer"
        v-for="societa in list"
        :key="societa.id"
        :value="societa.id"
        @click.prevent="
          $emit('selectedSocieta', {
            value: societa.id,
            name: `${societa.denominazione.trim()}(${societa.cod_affiliazione.trim()})`,
          })
        "
        @touchstart.prevent="
          $emit('selectedSocieta', {
            value: societa.id,
            name: `${societa.denominazione.trim()}(${societa.cod_affiliazione.trim()})`,
          })
        "
      >
        <a
          >{{ societa.denominazione.trim() }} ({{
            societa.cod_affiliazione.trim()
          }})</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { directive } from "vue3-click-away";

export default {
  name: "list-societa",
  props: ["list", "focusOnInput"],
  emits: ["selectedSocieta", "hideList"],
  directives: {
    ClickAway: directive,
  },
  setup(props, { emit }) {
    const onClickAway = () => {
      emit("hideList");
    };
    return { onClickAway };
  },
};
</script>

<style>
.list {
  position: absolute;
  z-index: 999;
  background-color: #f3f6f8;
  border: 1px solid #a3a7b8;
  border-radius: 0.475rem;
  list-style-type: none;
  width: 100%;
  padding-top: 1rem;
  margin-top: 0.5rem;
  padding-bottom: 1rem;
}
</style>
