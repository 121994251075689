<template>
  <div
    style="position: relative; width: 100%"
    v-click-away="onClickAway"
    v-show="list.length"
  >
    <ul class="list" v-if="list.length">
      <li
        class="text-hover-primary"
        style="cursor: pointer"
        v-for="tesserato in list"
        :key="tesserato.id"
        :value="tesserato.id"
        @click.prevent="
          $emit('selectedTesserato', {
            value: tesserato.id,
            name: `${tesserato.cognome.trim()},${tesserato.nome.trim()} ${
              tesserato.data_nascita
            }`,
            cognome: tesserato.cognome,
            data_nascita: tesserato.data_nascita,
            id: tesserato.id,
            itemId: tesserato.itemId,
            nome: tesserato.nome,
            obbligo_certificato: tesserato.obbligo_certificato,
          })
        "
        @touchstart.prevent="
          $emit('selectedTesserato', {
            value: tesserato.id,
            name: `${tesserato.cognome.trim()},${tesserato.nome.trim()} ${
              tesserato.data_nascita
            }`,
          })
        "
      >
        <a
          >{{ tesserato.cognome.trim() }}, {{ tesserato.nome.trim() }}
          {{ tesserato.data_nascita }}</a
        >
      </li>
    </ul>
  </div>
</template>

<script>
import { directive } from "vue3-click-away";

export default {
  name: "list-tesserati",
  props: ["list"],
  emits: ["selectedTesserato", "hideList"],
  directives: {
    ClickAway: directive,
  },
  setup(props, { emit }) {
    const onClickAway = () => {
      emit("hideList");
    };
    return { onClickAway };
  },
};
</script>

<style>
.list {
  position: absolute;
  z-index: 999;
  background-color: #f3f6f8;
  border: 1px solid #a3a7b8;
  border-radius: 0.475rem;
  list-style-type: none;
  width: 100%;
  padding-top: 1rem;
  margin-top: 0.5rem;
  padding-bottom: 1rem;
}
</style>
